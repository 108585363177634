<template>
  <center class="pa-2">
    <p v-if="($route.params && $route.params.deactivationMessage) || $route.query && $route.query.deactivationMessage">{{ $t('deactivated_account') }}</p>

    <v-row class="title py-4">
      <v-col cols="12">
        <img src="../assets/images/whatsApp_logo.png"
          v-on:click="whatsAppMessage()"
          width="100"
          height="100"
        />
        <p>{{ $t("whatsApp") }}</p>
      </v-col>

      <v-col cols="12">
        <img src="../assets/images/email_logo.png"
          v-on:click="email()"
          width="100"
          height="100"
        />
        <p>{{ $t("email") }} </p>
      </v-col>
    </v-row>
  </center>
</template>

<script>
export default {
  methods: {
    whatsAppMessage() {
      location.href = "https://wa.me/60320359220";
    },
    email() {
      location.href = "mailto: askus@insightzclub.com";
    },
  },
};
</script>